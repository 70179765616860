<template>
  <div class="up-add-edit-lecture">
    <AppBreadcrumbs :breadcrumbs="breadcrumbs" />
    <LectureForm
      v-model="lectureData"
      :attachments="attachments"
      @upload-file="uploadFile"
      @remove-file="removeFile"
    />
    <footer class="up-add-edit-lecture__footer">
      <el-button
        v-loading="loading"
        class="el-button el-button--danger"
        @click="cancelHandler"
      >
        Отменить
      </el-button>
      <el-button v-loading="loading" @click="previewHandler">
        Предпросмотр
      </el-button>
      <el-button
        v-loading="loading"
        type="success"
        @click="saveHandler"
        :disabled="buttonDisabled"
      >
        Сохранить
      </el-button>
    </footer>
  </div>
</template>

<script>
import addEditLectureMixin from "@/mixins/methodist/add-edit-lecture.mixin";

export default {
  name: "AddNewLecture",
  mixins: [addEditLectureMixin],
  data: () => ({
    loading: false,
  }),
  methods: {
    async saveHandler() {
      if (this.loading) return;

      this.$nprogress.start();
      this.loading = true;

      const attachments = this.attachments.map((a) => ({ id: a.id }));
      const cid = this.$route.params.id;

      try {
        await this.$axios.post(`/constructor/course/${cid}/lesson`, {
          type: "lecture",
          ...this.lectureData,
          attachments,
        });

        this.$message({
          message: "Поздравляем! Лекция успешно добавлена.",
          type: "success",
        });

        this.$router.push(`/methodist/courses/${cid}`);
        localStorage.removeItem("lecture-preview-data");
      } catch (e) {
        this.showErrorMessage(e);
      } finally {
        this.loading = false;
        this.$nprogress.done();
      }
    },
  },
  created() {
    this.setNavigationText("Добавление новой лекции");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },
  mounted() {
    const info = JSON.parse(localStorage.getItem("lecture-preview-data"));

    this.breadcrumbs.push({ text: "Добавление новой лекции" });

    if (info) {
      this.setDefaultData(info);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/methodist/courses/show/new-edit-lecture.scss";
</style>